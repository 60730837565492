import React, { useCallback } from 'react';
import { useTranslate } from '../../translations';
import { Role } from '../../enums/Role';
import { useUser } from '../../hooks/useUser';
import { useLogout } from '../../hooks/useLogout';
import { Dropdown } from '../dropdown';
import { CREATE_ORDER_PATH } from '../../constants/urls';
import { AccessControl } from '../../features/access-control';

export const NavbarUserMenu = () => {
  const translator = useTranslate();
  const user = useUser();
  const logout = useLogout();

  const onLogoutClick = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <Dropdown className="bg-transparent border-0" label={user.full_name ?? user.username}>
      <AccessControl roles={Role.ORDER_MANAGER}>
        <Dropdown.Item href={CREATE_ORDER_PATH}>{translator('new_order')}</Dropdown.Item>
      </AccessControl>
      <Dropdown.Item onClick={onLogoutClick}>{translator('logout')}</Dropdown.Item>
    </Dropdown>
  );
};
