import { useIntl, type PrimitiveType } from 'react-intl';
import { ReactElement, useCallback } from 'react';

export const useTranslate = () => {
  const intl = useIntl();

  return useCallback(
    (key: string, values?: Record<string, PrimitiveType | ReactElement>) => {
      if (!key) {
        return key;
      }

      return intl.formatMessage({ id: key }, values) as string;
    },
    [intl],
  );
};
