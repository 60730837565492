import React from 'react';
import { IconProps } from './model';
import { ReactComponent as Flag } from './assets/vi.svg';

export const ViFlagIcon = ({ ...props }: IconProps) => {
  return (
    <i {...props} className={props.className}>
      <Flag className="w-100" />
    </i>
  );
};
