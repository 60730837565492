import { ReactElement } from 'react';

export enum ToastType {
  SUCCESS = 0,
  ERROR = 1,
}

export interface ToastModel {
  id: number;
  type: ToastType;
  date: Date;
  message?: string | ReactElement;
}
