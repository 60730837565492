import React, { cloneElement, isValidElement } from 'react';
import { TOOLTIP_ID } from './consts';
import { TooltipProps } from './model';

export const Tooltip = ({ title, position = 'top', children }: TooltipProps) => {
  if (!children) {
    return null;
  }

  return cloneElement(isValidElement(children) ? children : <span>{children}</span>, {
    'data-tooltip-id': TOOLTIP_ID,
    'data-tooltip-html': title,
    'data-tooltip-place': position,
  });
};
