import { createContext, createElement, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { type IntlConfig, IntlProvider } from 'react-intl';
import { useLoadTranslations } from '../api/loadTranslations';
import { LocaleKey } from './consts';
import { saveLanguage } from './utils/saveLanguage';
import { useLocale } from './hooks/useLocale';
import { setAcceptLanguage } from '../utils/setAcceptLanguage';
import { getStoredLanguage } from './utils/getStoredLanguage';

interface TranslateContextType {
  locale: LocaleKey;
  setLocale: (locale: LocaleKey) => void;
}

const TranslateContext = createContext<TranslateContextType>({ locale: LocaleKey.HU, setLocale: () => null });

export const useTranslateContext = () => useContext(TranslateContext);

const ProviderContent = ({ children }: PropsWithChildren) => {
  const locale = useLocale();
  const messages = useLoadTranslations(locale);
  const contextValue: IntlConfig = useMemo(
    () => ({
      locale,
      messages,
      defaultRichTextElements: {
        br: () => {
          return createElement('br');
        },
      },
    }),
    [locale, messages],
  );

  if (Object.keys(messages).length === 0) {
    return null;
  }

  return createElement(IntlProvider, contextValue, children);
};

interface TranslateProviderProps extends PropsWithChildren {
  defaultLocale?: LocaleKey;
}

export const TranslateProvider = ({ defaultLocale = getStoredLanguage(), children }: TranslateProviderProps) => {
  const [locale, setLocale] = useState<LocaleKey>(defaultLocale);

  const contextValue = useMemo(
    () => ({
      locale,
      setLocale: (locale: LocaleKey) => {
        saveLanguage(locale);
        setAcceptLanguage(locale);
        setLocale(locale);
      },
    }),
    [locale],
  );

  return createElement(
    TranslateContext.Provider,
    { value: contextValue },
    createElement(ProviderContent, {}, children),
  );
};
