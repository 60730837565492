export const HOME_PATH = '/';
export const PROJECT_PATH = '/project/:id';
export const RECRUITMENT_PATH = `/project/:projectId/recruitment/:cycleId/:step?`;
export const CANDIDATE_PATH = '/project/:projectId/recruitment/:cycleId/:step?/candidate/:id';
export const CREATE_CANDIDATE_PATH = '/project/:id/recruitment/:cycleId/candidate/create';

export const CREATE_ADVERTISEMENT_PATH = '/advertisements/create';
export const USERS_PATH = '/users';
export const CREATE_USER_PATH = '/users/create';
export const WORKERS_PATH = '/project/:id?/workers';
export const ATTENDANCE_SHEET_PATH = `/project/:projectId/workers/:candidateId/attendance-sheet`;
export const ORDERS_PATH = '/orders';
export const CREATE_ORDER_PATH = `${ORDERS_PATH}/create`;
export const ORDER_PATH = `${ORDERS_PATH}/:id`;
