import { LocaleKey, STORAGE_LANGUAGE_KEY } from '../consts';

export const getStoredLanguage = () => {
  const storedLocale = localStorage.getItem(STORAGE_LANGUAGE_KEY);

  if (storedLocale && Object.values(LocaleKey).includes(storedLocale as LocaleKey)) {
    return storedLocale as LocaleKey;
  }

  return LocaleKey.HU;
};
