import * as Sentry from '@sentry/react';
import { BASE_URL, ENVIRONMENT, GIT_INFO } from './config';

export const initSentry = () => {
  if (ENVIRONMENT === 'development') {
    return;
  }

  Sentry.init({
    dsn: 'https://9e281c7daf38445ca1742aa6277d12da@o1002609.ingest.sentry.io/4504244734918656',
    tracePropagationTargets: ['localhost', BASE_URL],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: ENVIRONMENT,
    release: GIT_INFO,
  });
};
