import axios from 'axios';
import { BASE_URL } from '../config';
import { getStoredLanguage } from '../translations';

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    common: {
      'Accept-Language': getStoredLanguage(),
    },
  },
});

export default instance;
