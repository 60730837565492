import React, { ComponentType, PropsWithChildren } from 'react';
import { IconProps } from './icon/model';

interface AlertProps extends PropsWithChildren {
  className?: string;
  color?: 'primary' | 'success' | 'danger' | 'info' | 'warning';
  icon?: ComponentType<IconProps>;
}

export const Alert = ({ color = 'primary', children, className, icon: Icon }: AlertProps) => {
  return (
    <div className={`alert alert-${color} ${className}`} role="alert">
      {Icon && <Icon />}
      {children}
    </div>
  );
};
