import { useNavigate } from 'react-router-dom';
import User from '../models/User';
import { useMemo } from 'react';
import { useMe } from '../features/user/api';

export const useUser = (): User => {
  const { data } = useMe();
  const navigate = useNavigate();

  if (!data) {
    navigate('/login');
    return null as unknown as User;
  }

  return useMemo(() => {
    return { ...data, roles: Object.values(data.roles) };
  }, [data]);
};
