import React, { createElement, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { TooltipAware, TooltipParameter } from '../tooltip';

export interface BadgeProps extends PropsWithChildren {
  color?: 'primary' | 'success' | 'danger' | 'info' | 'warning';
  className?: string;
  tooltip?: TooltipParameter;
}

export const Badge = ({ color = 'primary', children, className, tooltip: TooltipComponent, ...props }: BadgeProps) => {
  const content = (
    <span {...props} className={classNames(`badge bg-${color}`, className)}>
      {children}
    </span>
  );

  if (TooltipComponent) {
    return createElement(TooltipAware, { tooltip: TooltipComponent }, content);
  }

  return content;
};
