import React from 'react';
import { Spinner } from '../spinner';

export const LoadingPage = () => {
  return (
    <div className="container-lg">
      <div className="row mb-5">
        <div className="text-center">
          <Spinner />
        </div>
      </div>
    </div>
  );
};
