import React, { createContext, PropsWithChildren, ReactElement, useContext, useMemo } from 'react';

interface ProgressContextType {
  min: number;
  max: number;
}

const ProgressContext = createContext<ProgressContextType>({ min: 0, max: 0 });

interface ProgressProps {
  min: number;
  max: number;
  children: ReactElement<ProgressBarProps> | ReactElement<ProgressBarProps>[];
}

export const Progress = ({ min, max, children, ...props }: ProgressProps) => {
  const contextValue = useMemo(() => ({ min, max }), [min, max]);

  return (
    <ProgressContext.Provider value={contextValue}>
      <div className="progress" {...props}>
        {children}
      </div>
    </ProgressContext.Provider>
  );
};

interface ProgressBarProps {
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger';
  value: number;
}

const ProgressBar = ({ value, color = 'primary', children }: PropsWithChildren<ProgressBarProps>) => {
  const { min, max } = useContext(ProgressContext);

  if (value < min || value > max) {
    return null;
  }

  const percentage = Math.round((value / (max - min)) * 100);

  return (
    <div
      className={`progress-bar bg-${color}`}
      role="progressbar"
      style={{ width: `${percentage}%` }}
      aria-valuenow={value}
      aria-valuemin={min}
      aria-valuemax={max}
    >
      {children}
    </div>
  );
};

Progress.Bar = ProgressBar;
