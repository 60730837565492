import React, { lazy, ReactElement, Suspense } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { ProtectedRoute, LoggedIn, LoadingPage } from './components/layout';
import { Role } from './enums/Role';
import { useHasAccess } from './features/access-control';
import {
  ATTENDANCE_SHEET_PATH,
  CANDIDATE_PATH,
  CREATE_ADVERTISEMENT_PATH,
  CREATE_CANDIDATE_PATH,
  CREATE_ORDER_PATH,
  CREATE_USER_PATH,
  HOME_PATH,
  ORDER_PATH,
  ORDERS_PATH,
  PROJECT_PATH,
  RECRUITMENT_PATH,
  USERS_PATH,
  WORKERS_PATH,
} from './constants/urls';
import { ErrorPage } from './pages/error';

const DashboardPage = lazy(() => import('./pages/dashboard/DashboardPage'));
const CreateCandidatePage = lazy(() => import('./pages/candidate/CreateCandidatePage'));
const CandidatePage = lazy(() => import('./pages/candidate/CandidatePage'));
const ProjectPage = lazy(() => import('./pages/project'));
const RecruitmentPage = lazy(() => import('./pages/recruitment/RecruitmentPage'));
const CreateNewAdvertisementPage = lazy(() => import('./pages/advertisement/CreateAdvertPage'));
const AuthenticationPage = lazy(() => import('./pages/authentication/AuthenticationPage'));
const CreateUserPage = lazy(() => import('./pages/users/CreateUserPage'));
const ListUsersPage = lazy(() => import('./pages/users/ListUsersPage'));
const CreateOrderPage = lazy(() => import('./pages/order/CreateOrderPage'));
const WorkersListPage = lazy(() => import('./pages/project/WorkersListPage'));
const AttendanceSheetPage = lazy(() => import('./pages/project/AttendanceSheetPage'));
const ProjectListPage = lazy(() => import('./pages/project/ProjectListPage'));
const OrderListPage = lazy(() => import('./pages/order/OrderListPage'));
const UpdateOrderPage = lazy(() => import('./pages/order/UpdateOrderPage'));

interface RolesAuthRouteProps {
  requiredRoles?: Role[] | Role;
  excludingRoles?: Role[] | Role;
  children: ReactElement;
}

const RolesAuthRoute = ({ requiredRoles, excludingRoles, children }: RolesAuthRouteProps) => {
  const hasAccess = useHasAccess();
  const hasRequiredRole = !requiredRoles || hasAccess(requiredRoles);
  const hasExcludingRole = excludingRoles && hasAccess(excludingRoles);

  return hasRequiredRole && !hasExcludingRole ? children : <Navigate to={'/'} replace />;
};

const useRouter = () => {
  return createBrowserRouter([
    {
      path: HOME_PATH,
      element: <ProtectedRoute />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: HOME_PATH,
          element: <LoggedIn />,
          children: [
            {
              path: HOME_PATH,
              element: <DashboardPage />,
              children: [
                {
                  path: HOME_PATH,
                  element: <ProjectListPage />,
                },
                {
                  path: ORDERS_PATH,
                  element: (
                    <RolesAuthRoute requiredRoles={Role.CREATE_ORDER}>
                      <OrderListPage />
                    </RolesAuthRoute>
                  ),
                },
              ],
            },
            {
              path: CREATE_CANDIDATE_PATH,
              element: (
                <RolesAuthRoute requiredRoles={[Role.EMPLOYEE_UPLOAD]}>
                  <CreateCandidatePage />
                </RolesAuthRoute>
              ),
            },
            {
              path: CANDIDATE_PATH,
              element: <CandidatePage isEditable={false} />,
            },
            {
              path: PROJECT_PATH,
              element: (
                <RolesAuthRoute requiredRoles={[Role.SUBCONTRACTOR_USER, Role.CREATE_ORDER]}>
                  <ProjectPage />
                </RolesAuthRoute>
              ),
            },
            {
              path: RECRUITMENT_PATH,
              element: <RecruitmentPage />,
            },
            {
              path: CREATE_ORDER_PATH,
              element: (
                <RolesAuthRoute requiredRoles={Role.CREATE_ORDER}>
                  <CreateOrderPage />
                </RolesAuthRoute>
              ),
            },
            {
              path: ORDER_PATH,
              element: (
                <RolesAuthRoute requiredRoles={Role.CREATE_ORDER}>
                  <UpdateOrderPage />
                </RolesAuthRoute>
              ),
            },
            {
              path: CREATE_ADVERTISEMENT_PATH,
              element: (
                <RolesAuthRoute requiredRoles={[Role.ADVERT]}>
                  <CreateNewAdvertisementPage />
                </RolesAuthRoute>
              ),
            },
            {
              path: USERS_PATH,
              element: (
                <RolesAuthRoute requiredRoles={[Role.CREATE_USER]}>
                  <ListUsersPage />
                </RolesAuthRoute>
              ),
            },
            {
              path: CREATE_USER_PATH,
              element: (
                <RolesAuthRoute requiredRoles={[Role.CREATE_USER]}>
                  <CreateUserPage />
                </RolesAuthRoute>
              ),
            },
            {
              path: WORKERS_PATH,
              element: (
                <RolesAuthRoute requiredRoles={Role.LIST_EMPLOYEES}>
                  <WorkersListPage />
                </RolesAuthRoute>
              ),
            },
            {
              path: ATTENDANCE_SHEET_PATH,
              element: (
                <RolesAuthRoute requiredRoles={Role.ATTENDANCE_ADMIN}>
                  <AttendanceSheetPage />
                </RolesAuthRoute>
              ),
            },
          ],
        },
      ],
    },
    {
      path: '/login',
      element: <AuthenticationPage />,
    },
    {
      path: '*',
      element: <Navigate to={HOME_PATH} />,
    },
  ]);
};

export const Routes = () => {
  const router = useRouter();

  return (
    <Suspense fallback={<LoadingPage />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};
