import React, { ComponentType, isValidElement } from 'react';
import { ButtonProps } from './model';
import { Button } from './Button';
import { IconProps } from '../icon/model';
import styles from './Button.module.scss';
import { useIsMobile } from '../../hooks/useIsMobile';

interface IconButtonProps extends Omit<ButtonProps, 'children' | 'iconAlign'> {
  icon: ComponentType<IconProps>;
}

export const IconButton = ({ icon: Icon, tooltip, ...props }: IconButtonProps) => {
  const isMobile = useIsMobile();

  if (isMobile && tooltip) {
    return (
      <Button {...props} className={`${props.className}`} icon={Icon}>
        {isValidElement(tooltip) ? tooltip.props.title : tooltip}
      </Button>
    );
  }

  return (
    <Button
      {...props}
      tooltip={tooltip}
      className={`${props.className} ${styles.iconButton} d-inline-flex justify-content-center`}
    >
      <Icon />
    </Button>
  );
};
