import { createContext, createElement, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import setAuthorizationToken from '../utils/setAuthorizationToken';
import addDefaultAxiosErrorResponseHandler from '../utils/axiosGlobalErrorResponseHandler';
import { useMe } from '../features/user/api';

interface AuthContextProps {
  token: string | null;
  setToken: (token: string) => void;
}

const AuthContext = createContext<AuthContextProps>({
  token: null,
  setToken: () => null,
});
const UserProvider = ({ children }: PropsWithChildren) => {
  const { token } = useToken();
  const { isLoading } = useMe(token);

  if (isLoading) {
    return null;
  }

  return children;
};

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('jwtToken'));

  const saveToken = useCallback((newToken: string) => {
    localStorage.setItem('jwtToken', newToken);
    setToken(newToken);
  }, []);

  useEffect(() => {
    addDefaultAxiosErrorResponseHandler(setToken);
  }, []);

  useEffect(() => {
    setAuthorizationToken(token);
  }, [token]);

  return createElement(
    AuthContext.Provider,
    { value: { token, setToken: saveToken } },
    createElement(UserProvider, {}, children),
  );
};

export const useToken = () => {
  const { token, setToken } = useContext(AuthContext);

  return {
    setToken,
    token,
  };
};
