import axios from 'axios';

export default function addDefaultAxiosErrorResponseHandler(setToken: (token: string) => void) {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error;
      if (!response || !response.data) {
        return;
      }

      const { logoutClient } = response.data;
      if (logoutClient) {
        setToken('');
        window.location.reload();
        return;
      }

      return Promise.reject(error);
    },
  );
}
