const {
  VITE_BASE_URL: BASE_URL,
  VITE_IS_SAFE_IN_HUNGARY_APP,
  VITE_GOOGLE_MAPS_API_KEY: GOOGLE_MAPS_API_KEY,
  MODE: ENVIRONMENT,
  VITE_GIT_INFO: GIT_INFO,
} = import.meta.env;

const IS_SAFE_IN_HUNGARY_APP = VITE_IS_SAFE_IN_HUNGARY_APP === 'true';

export { BASE_URL, IS_SAFE_IN_HUNGARY_APP, GOOGLE_MAPS_API_KEY, ENVIRONMENT, GIT_INFO };
