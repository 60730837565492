import React, { useMemo } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslate } from '../../translations';
import {
  ATTENDANCE_SHEET_PATH,
  CANDIDATE_PATH,
  CREATE_ADVERTISEMENT_PATH,
  CREATE_CANDIDATE_PATH,
  CREATE_ORDER_PATH,
  CREATE_USER_PATH,
  HOME_PATH,
  ORDER_PATH,
  ORDERS_PATH,
  PROJECT_PATH,
  RECRUITMENT_PATH,
  USERS_PATH,
  WORKERS_PATH,
} from '../../constants/urls';

const useBreadcrumbsNameMap = () => {
  const translate = useTranslate();

  return useMemo(() => {
    const breadcrumbNameMap: Record<string, string> = {
      [HOME_PATH]: translate('home'),
      [WORKERS_PATH]: translate('workers.list'),
      [PROJECT_PATH]: translate('project_data_sheet'),
      [RECRUITMENT_PATH]: translate('recruitment_title'),
      [CANDIDATE_PATH]: translate('candidate_data_sheet'),
      [CREATE_CANDIDATE_PATH]: translate('create_new_candidate'),
      [CREATE_ADVERTISEMENT_PATH]: translate('new_advertisement'),
      [USERS_PATH]: translate('users.list'),
      [CREATE_USER_PATH]: translate('users.add'),
      [ATTENDANCE_SHEET_PATH]: translate('attendance_sheet.list'),
      [ORDERS_PATH]: translate('orders.list.page.title'),
      [CREATE_ORDER_PATH]: translate('orders.add'),
      [ORDER_PATH]: translate('orders.update'),
    };

    return breadcrumbNameMap;
  }, [translate]);
};

export const Breadcrumbs = () => {
  const translate = useTranslate();
  const location = useLocation();
  const pathNames = location.pathname.split('/').filter((x) => x);
  const breadcrumbNameMap = useBreadcrumbsNameMap();

  const breadcrumbItems = useMemo(() => {
    const getPathname = (index: number) => {
      return '/' + pathNames.slice(0, index + 1).join('/');
    };

    const getBreadcrumbName = (pathname: string) => {
      const matchedRoute = Object.keys(breadcrumbNameMap).find((route) => matchPath(route, pathname));

      if (matchedRoute) {
        return breadcrumbNameMap[matchedRoute];
      }
      return null;
    };

    return [
      { name: translate('home'), pathName: '/' },
      ...pathNames
        .map((_, index) => {
          const pathName = getPathname(index);
          const name = getBreadcrumbName(pathName);

          return {
            pathName,
            name,
          };
        })
        .filter((item) => !!item.name)
        .reverse()
        .reduce<{ pathName: string; name: string | null }[]>((items, currentItem) => {
          if (items.some((item) => item.name === currentItem.name)) {
            return items;
          }

          return [...items, currentItem];
        }, [])
        .reverse(),
    ];
  }, [breadcrumbNameMap, pathNames, translate]);

  return (
    <nav>
      <ol className="breadcrumb">
        {breadcrumbItems.map((item) => {
          const isActive = item.pathName === location.pathname;

          return (
            <li
              key={item.pathName}
              className={classNames('breadcrumb-item', {
                active: isActive,
              })}
              aria-current={isActive ? 'page' : undefined}
            >
              {isActive ? item.name : <Link to={item.pathName}>{item.name}</Link>}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
