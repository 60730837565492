import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../../api/axios';
import { User, UserPayload } from './model';
import { useToast } from '../../providers/ToastProvider';
import { useTranslate } from '../../translations';
import { ToastType } from '../../models/Toast';
import { ApiErrorResponse } from '../../models/Type';
import LoggedUser from '../../models/User';
import { useLogout } from '../../hooks/useLogout';

export const useCreateUserMutation = () => {
  const { addToast } = useToast();
  const translator = useTranslate();
  const queryClient = useQueryClient();
  const globalOnError = queryClient.getDefaultOptions().mutations?.onError;

  return useMutation({
    mutationFn: (payload: UserPayload) => {
      return axios.post('/users.php', payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    },
    onError: (error, ...params) => {
      const status = (error as ApiErrorResponse)?.response?.status;

      if (status === 409) {
        return addToast({
          type: ToastType.ERROR,
          message: translator('users.create.error.duplicate'),
        });
      }

      globalOnError?.(error, ...params);
    },
    onSuccess: () => {
      addToast({
        type: ToastType.SUCCESS,
        message: translator('users.create.success'),
      });
    },
  });
};

export const useUserActivationMutation = (userId: string) => {
  const { addToast } = useToast();
  const translator = useTranslate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (enabled: boolean) => {
      return axios.put(`/users.php?id=${userId}&enabled=${enabled ? '1' : '0'}`);
    },
    onSuccess: () => {
      addToast({
        type: ToastType.SUCCESS,
        message: translator('users.activation.success'),
      });
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export const useUsers = () => {
  return useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      try {
        const response = await axios.get('/users.php');

        return response.data.data as User[];
      } catch (error) {
        return [] as User[];
      }
    },
  });
};

export const useMe = (token?: string | null) => {
  const logout = useLogout(true);

  return useQuery({
    queryKey: ['me'],
    queryFn: async () => {
      const response = await axios.get('/me.php', {
        headers: {
          Accept: 'application/json',
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      });

      return response.data.data as LoggedUser;
    },
    throwOnError: () => {
      logout();

      return true;
    },
    enabled: !!token,
  });
};
