import { type Metric, onCLS, onINP, onLCP } from 'web-vitals/attribution';

export const reportWebVitals = (onPerfEntry?: (metric: Metric) => void) => {
  if (!onPerfEntry) {
    return;
  }

  onCLS(onPerfEntry);
  onINP(onPerfEntry);
  onLCP(onPerfEntry);
};
