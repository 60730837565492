import axios from 'axios';
import customAxiosInstance from '../api/axios';

export default function setAuthorizationToken(token?: string | null) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    customAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
    delete customAxiosInstance.defaults.headers.common['Authorization'];
  }
}
