import React from 'react';
import { useTranslate } from '../../translations';

interface SpinnerProps {
  size?: 'sm';
}

export const Spinner = ({ size }: SpinnerProps) => {
  const translator = useTranslate();

  return (
    <div className={`spinner-border ${size ? 'spinner-border-sm' : ''}`} role="status">
      <span className="visually-hidden">{translator('loading_dots')}</span>
    </div>
  );
};
