import React from 'react';
import { useTranslate, useLocale, useSetLocale, localeIconMapping } from '../../translations';
import styles from './NavbarLanguageMenu.module.scss';
import { useLanguageOptions } from '../../hooks/useLanguageOptions';
import { Dropdown } from '../dropdown';

export const NavbarLanguageMenu = () => {
  const translator = useTranslate();
  const locale = useLocale();
  const setLocale = useSetLocale();
  const languageOptions = useLanguageOptions();
  const CurrentLocaleIcon = localeIconMapping[locale];

  return (
    <Dropdown
      color="light"
      label={
        <>
          <CurrentLocaleIcon className={styles.flagIcon} /> {translator(`language_${locale}`)}
        </>
      }
      className={styles.dropdownButton}
    >
      {languageOptions.map((item) => {
        const FlagIcon = localeIconMapping[item.value];
        return (<Dropdown.Item key={item.value} onClick={() => setLocale(item.value)}>
          <FlagIcon className={styles.flagIcon} /> {item.text}
        </Dropdown.Item>)
      })}
    </Dropdown>
  );
};
