import React, { useCallback, useEffect } from 'react';
import { useToast } from '../../providers/ToastProvider';
import { ToastModel, ToastType } from '../../models/Toast';
import { useTranslate, useFormatDateTime } from '../../translations';

interface Props {
  data: ToastModel;
}

const Toast: React.FC<Props> = ({ data: { id, message, type, date } }) => {
  const translator = useTranslate();
  const formatDateTime = useFormatDateTime();
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(id);
    }, 15 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [id, removeToast]);

  const removeSelf = useCallback(() => {
    removeToast(id);
  }, [removeToast, id]);

  const headerBackgroundClass = type === ToastType.ERROR ? 'bg-danger' : 'bg-success';

  return (
    <div
      className="animate__animated animate__fadeInLeft animate__faster toast border-0 show"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className={`toast-header border-0 ${headerBackgroundClass} text-white d-flex justify-content-between`}>
        <small>{formatDateTime(date)}</small>
        <button
          type="button"
          className="btn text-white"
          data-bs-dismiss="toast"
          aria-label={translator('close')}
          onClick={removeSelf}
        >
          <i className="fa fa-times fs-5"></i>
        </button>
      </div>
      <div className="toast-body bg-white">{message}</div>
    </div>
  );
};

export default Toast;
