import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import { reportWebVitals } from './reportWebVitals';

import App from './App';

import 'animate.css/animate.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/js/bootstrap';
import { initSentry } from './sentry';
import { loadErrorHandling } from './utils/loadErrorHandling';

initSentry();
loadErrorHandling();
const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(createElement(App));
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
