import { LocaleKey, useTranslate } from '../translations';
import { useMemo } from 'react';

interface LanguageOption {
  value: LocaleKey;
  text: string;
}

export const useLanguageOptions: () => LanguageOption[] = () => {
  const translator = useTranslate();

  return useMemo(
    () =>
      Object.values(LocaleKey).map((key) => ({
        value: key,
        text: translator(`language_${key}`),
      })),
    [translator],
  );
};
