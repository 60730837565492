import { AppType } from '../../utils/getAppName';
import React, { createContext, lazy, PropsWithChildren, ReactElement, useContext, useEffect } from 'react';

const ProhumanTheme = lazy(() => import('./ProhumanTheme'));
const ServiceFlowTheme = lazy(() => import('./ServiceFlowTheme'));
const HrRentTheme = lazy(() => import('./HrRentTheme'));
const ProPoolTheme = lazy(() => import('./ProPoolTheme'));
const SafeInHungaryTheme = lazy(() => import('./SafeInHungaryTheme'));
const ProdiakTheme = lazy(() => import('./ProdiakTheme'));

interface Theme {
  title: string;
  type: AppType;
  login?: {
    title?: string | ReactElement;
    logo?: ReactElement;
    background?: ReactElement;
  };
  headerLogo: ReactElement;
  favicon: string;
  dashboardBackground?: ReactElement;
}

export const ThemeContext = createContext<Theme | null>(null);

interface ThemeSelectorProps extends PropsWithChildren {
  themeName: AppType;
}

const themeComponentMapping = {
  [AppType.PROHUMAN]: ProhumanTheme,
  [AppType.SERVICE_FLOW]: ServiceFlowTheme,
  [AppType.PROPOOL]: ProPoolTheme,
  [AppType.SAFE_IN_HUNGARY]: SafeInHungaryTheme,
  [AppType.HR_RENT]: HrRentTheme,
  [AppType.PRODIAK]: ProdiakTheme,
};

export const ThemeSelector = ({ children, themeName }: ThemeSelectorProps) => {
  useEffect(() => {
    document.body.classList.add(themeName);

    return () => document.body.classList.remove(themeName);
  }, [themeName]);

  const ThemeComponent = themeComponentMapping[themeName];

  if (ThemeComponent) {
    return <ThemeComponent>{children}</ThemeComponent>;
  }

  return <ProhumanTheme>{children}</ProhumanTheme>;
};

export const useTheme = () => {
  return useContext(ThemeContext) ?? ({} as Theme);
};
