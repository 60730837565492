import React, { createElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ButtonProps } from './model';
import styles from './Button.module.scss';
import { Spinner } from '../spinner';
import { TooltipAware } from '../tooltip';
import classNames from 'classnames';

const ButtonLoading = () => {
  return (
    <div className={styles.loader}>
      <Spinner size="sm" />
    </div>
  );
};

export const Button = ({
  color = 'primary',
  children,
  onClick,
  href,
  size,
  loading,
  disabled,
  className = '',
  type = 'button',
  outline = false,
  fill = false,
  tooltip: TooltipComponent,
  icon: Icon = () => null,
  iconAlign,
  target,
  download,
  textAlign = 'center',
  ...otherProps
}: ButtonProps) => {
  const props = useMemo(
    () => ({
      className: classNames(
        'btn',
        `btn-${outline ? 'outline-' : ''}${color}`,
        styles.button,
        className,
        styles[color],
        {
          [`btn-${size}`]: size,
          [styles.loading]: loading,
          [styles.outline]: outline,
          'w-100': fill,
        },
      ),
      onClick,
      ...otherProps,
    }),
    [className, color, fill, loading, onClick, otherProps, outline, size],
  );

  const contentClass = classNames(`d-flex align-items-center justify-content-${textAlign} h-100`, {
    'flex-row-reverse': iconAlign === 'end',
    'gap-3': size !== 'sm',
    'gap-1': size === 'sm',
  });

  const content = useMemo(
    () =>
      href ? (
        <Link to={href} target={target} download={download} {...props}>
          <span className={contentClass}>
            <>
              <Icon />
              {children}
            </>
          </span>
          {loading && <ButtonLoading />}
        </Link>
      ) : (
        <button {...props} disabled={disabled || loading} type={type}>
          <span className={contentClass}>
            <>
              <Icon />
              {children}
            </>
          </span>
          {loading && <ButtonLoading />}
        </button>
      ),
    [children, contentClass, disabled, href, Icon, loading, props, type, target, download],
  );

  if (TooltipComponent) {
    return createElement(TooltipAware, { tooltip: TooltipComponent }, content);
  }

  return content;
};
