import { useHasAccess } from './hooks/useHasAccess';
import { Role } from '../../enums/Role';
import { LogicalOperator } from './model';
import React, { FunctionComponent, PropsWithChildren } from 'react';

interface AccessControlProps extends PropsWithChildren {
  roles?: Role[] | Role;
  operator?: LogicalOperator;
  renderNoAccess?: FunctionComponent;
}
export const AccessControl = ({
  roles,
  operator,
  children,
  renderNoAccess: NoAccessComponent = () => null,
}: AccessControlProps) => {
  const hasAccess = useHasAccess();

  return hasAccess(roles ?? [], operator) ? children : <NoAccessComponent />;
};
