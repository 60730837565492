import { useIntl } from 'react-intl';
import { useCallback } from 'react';
export const useFormatDateTime = () => {
  const intl = useIntl();

  return useCallback(
    (value?: string | number | Date) => {
      return intl.formatDate(value, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        second: '2-digit',
        minute: '2-digit',
      }) as string;
    },
    [intl],
  );
};
