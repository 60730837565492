import { useEffect, useState } from 'react';
import { LocaleKey, Messages } from '../translations';

export const useLoadTranslations = (locale = LocaleKey.HU) => {
  const [messages, setMessages] = useState<Messages>({});

  useEffect(() => {
    const fetchMessages = async () => {
      const response = await fetch(`/translations/${locale}.json`, { cache: 'no-store' });

      setMessages(await response.json());
    };

    fetchMessages();
  }, [locale]);

  return messages;
};
