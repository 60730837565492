import * as React from 'react';
import { ToastModel } from '../../models/Toast';
import Toast from './Toast';

interface Props {
  toasts: ToastModel[];
}

const ToastContainer: React.FC<Props> = ({ toasts }) => {
  return (
    <div className="toast-container position-fixed bottom-0 start-0 p-3">
      {toasts.map((toast: ToastModel) => (
        <Toast key={`toast-${toast.id}`} data={toast} />
      ))}
    </div>
  );
};

export default ToastContainer;
