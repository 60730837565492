import React from 'react';
import { useTranslate } from '../../translations';
import { Button } from '../../components/button';
import styles from './ErrorPage.module.scss';

export const ErrorPage = () => {
  const translate = useTranslate();

  return (
    <div className={styles.errorWrapper}>
      <div>
        <h1>Oops!</h1>
        <h3>{translate('error_page.title')}</h3>
        <Button href="/">{translate('error_page.back')}</Button>
      </div>
      <div>
        <i className={`fa-solid fa-triangle-exclamation ${styles.icon}`}></i>
      </div>
    </div>
  );
};
