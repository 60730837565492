export enum Role {
  FREE_FOR_ALL = 'ROLE_PARTNER_CLIENT_FREE_FOR_ALL',
  SUBCONTRACTOR_USER = 'ROLE_PROBOND_PARTNER_CLIENT_SUBCONTRACTOR_USER',
  MOVING_EMPLOYEES = 'ROLE_PROBOND_PARTNER_CLIENT_MOVING_EMPLOYEES',
  ORDER_MANAGER = 'ROLE_PARTNER_CLIENT_ORDER_MANAGER',
  EMPLOYEE_UPLOAD = 'ROLE_EXT_PC_EMPLOYEE_UPLOAD',
  EMPLOYEE_IMPORT = 'ROLE_EXT_PC_EMPLOYEE_IMPORT',
  ADVERT = 'ROLE_PROBOND_PARTNER_CLIENT_ADVERT_CUSTOMER_USER',
  SELECT_CONSULATE = 'ROLE_PROBOND_PARTNER_CLIENT_SELECT_CONSULATE',
  MOVING_ENTRY_EMPLOYEES = 'ROLE_PROBOND_PARTNER_CLIENT_MOVING_ENTRY_EMPLOYEES',
  DOCUMENT_BOX_MANAGER = 'ROLE_DOCUMENT_BOX_MANAGER',
  CREATE_USER = 'ROLE_EXT_USER_ADMIN',
  ATTENDANCE_ADMIN = 'ROLE_EXT_ATTENDANCE_ADMIN',
  CREATE_ORDER = 'ROLE_EXT_PROJECT_ADMIN',
  KEY_USER = 'ROLE_PARTNER_CLIENT_CUSTOMER_KEY_USER',
  LIST_EMPLOYEES = 'ROLE_PK_MY_ALL_EMPLOYEE_LIST',
}
