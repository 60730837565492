import { cloneElement, createElement, isValidElement, PropsWithChildren } from 'react';
import { Tooltip } from './Tooltip';
import { TooltipParameter } from './model';

interface TooltipAwareProps extends PropsWithChildren {
  tooltip?: TooltipParameter;
}

export const TooltipAware = ({ tooltip: TooltipComponent, children }: TooltipAwareProps) => {
  if (TooltipComponent && isValidElement(TooltipComponent)) {
    return cloneElement(TooltipComponent, {}, children);
  }

  if (TooltipComponent) {
    return createElement(Tooltip, { title: TooltipComponent }, children);
  }
};
