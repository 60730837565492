import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslate } from '../../translations';
import { NavbarUserMenu } from './NavbarUserMenu';
import { NavbarLanguageMenu } from './NavbarLanguageMenu';
import styles from './Navbar.module.scss';
import { useTheme } from '../../styles/themes/ThemeSelector';
import { JobQueueToggle } from '../../features/jobs-queue';
import { HOME_PATH, ORDERS_PATH } from '../../constants/urls';

const Navbar = () => {
  const translator = useTranslate();
  const { headerLogo } = useTheme();
  const location = useLocation();
  const isOrdersPage = location.pathname.includes(ORDERS_PATH);

  return (
    <nav className={`navbar navbar-expand-lg fixed-top py-3 ${styles.navbar}`}>
      <div className="container-fluid py-1">
        <Link to={isOrdersPage ? ORDERS_PATH : HOME_PATH} className="navbar-brand px-3">
          {headerLogo}
        </Link>
        <div className="ms-auto me-4">
          <JobQueueToggle />
        </div>
        <button
          className="navbar-toggler border-white text-white py-2 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-toggler"
          aria-controls="navbar-toggler"
          aria-expanded="false"
          aria-label={translator('toggle_navigation')}
        >
          <i className="fas fa-light fa-bars"></i>
        </button>
        <div className="collapse navbar-collapse flex-grow-0 gap-3" id="navbar-toggler">
          <NavbarLanguageMenu />
          <NavbarUserMenu />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
