export enum LogicalOperator {
  OR,
  AND,
}

export type WithAccessControl<Model> = Partial<
  Record<
    keyof Model,
    {
      read: boolean;
      edit?: boolean;
      write?: boolean;
    }
  >
>;
