import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useToken } from '../../hooks/useToken';

export const ProtectedRoute = () => {
  const { token } = useToken();

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};
