import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconButton } from '../../../components/button/IconButton';
import { ProgressBarsIcon } from '../../../components/icon';
import classNames from 'classnames';
import styles from './JobQueueToggle.module.scss';
import { Alert } from '../../../components/Alert';
import { Progress } from '../../../components/progress';
import { useJobQueue } from '../JobQueueProvider';
import { Badge } from '../../../components/badge';
import { Tooltip } from '../../../components/tooltip';
import { useTranslate } from '../../../translations';
import { useToast } from '../../../providers/ToastProvider';
import { ToastType } from '../../../models/Toast';

export const JobQueueToggle = () => {
  const translator = useTranslate();
  const { jobQueueState, jobQueueDispatch } = useJobQueue();
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const { addToast } = useToast();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target instanceof HTMLElement) || !containerRef.current?.contains(event.target)) {
        setShow(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const beforeUnloadHandler = (event: Event) => {
      if (jobQueueState.jobs.length > 0) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [jobQueueState.jobs.length]);

  useEffect(() => {
    if (progress !== 0 || jobQueueState.jobs.length === 0) {
      return;
    }
    const processNextJob = async () => {
      if (jobQueueState.jobs.length > 0) {
        const jobToProcess = jobQueueState.jobs[0];
        setProgress(1);
        await jobToProcess.job
          .runner((progress) => {
            setProgress(progress);
          })
          .then(() => {
            setProgress(100);
            addToast({
              type: ToastType.SUCCESS,
              message: translator('job-queue.task.success', { name: jobToProcess.job.name }),
            });
          })
          .catch((error) => {
            addToast({
              type: ToastType.ERROR,
              message: (
                <>
                  {translator('job-queue.task.fail', { name: jobToProcess.job.name })}
                  {typeof error.message === 'string' && (
                    <>
                      <br />
                      <span dangerouslySetInnerHTML={{ __html: error.message }} />
                    </>
                  )}
                </>
              ),
            });
          })
          .finally(() => {
            jobQueueDispatch({ type: 'REMOVE_JOB', payload: jobToProcess.id });
            setProgress(0);
          });
      }
    };

    processNextJob();
  }, [jobQueueState.jobs, jobQueueDispatch, progress, addToast, translator]);

  const handleToggle = useCallback(() => {
    setShow((show) => !show);
  }, []);

  const title = `${translator('job-queue.title')} (${jobQueueState.jobs.length})`;

  return (
    <div ref={containerRef} className="position-relative">
      <Tooltip title={title}>
        <div className="position-relative">
          <Badge className={styles.badge} color="danger">
            {jobQueueState.jobs.length}
          </Badge>
          <IconButton color="light" icon={ProgressBarsIcon} onClick={handleToggle} />
        </div>
      </Tooltip>
      {show && (
        <div className={classNames(styles.container, 'shadow rounded p-3')}>
          <h6 className="fw-bold">{title}</h6>
          {jobQueueState.jobs.length === 0 && <Alert>{translator('job-queue.empty')}</Alert>}
          {jobQueueState.jobs.map((job, index) => (
            <div key={job.id} className="border-top py-2">
              <p className="d-flex align-items-center gap-2 mb-2">{job.job.name}</p>
              <Progress min={0} max={100}>
                <Progress.Bar value={index === 0 ? progress : 0}>{index === 0 ? progress : 0} %</Progress.Bar>
              </Progress>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
