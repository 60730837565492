import React, { createContext, useContext, useReducer, ReactNode, Dispatch, useCallback } from 'react';

interface JobPayload {
  name: string;
  runner: (onProgress: (progress: number) => void) => Promise<void>;
}

interface Job {
  id: number;
  job: JobPayload;
}

interface JobQueueState {
  jobs: Job[];
}

type JobQueueAction =
  | { type: 'ADD_JOB'; payload: JobPayload }
  | { type: 'REMOVE_JOB'; payload: number }
  | { type: 'RESET_JOBS' };

interface JobQueueContextProps {
  jobQueueState: JobQueueState;
  jobQueueDispatch: Dispatch<JobQueueAction>;
}

const JobQueueContext = createContext<JobQueueContextProps | undefined>(undefined);

const initialState: JobQueueState = {
  jobs: [],
};

export const JobQueueProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const jobQueueReducer = useCallback((state: JobQueueState, action: JobQueueAction): JobQueueState => {
    switch (action.type) {
      case 'ADD_JOB':
        return {
          ...state,
          jobs: [
            ...state.jobs,
            {
              id: state.jobs.length + 1,
              job: action.payload,
            },
          ],
        };
      case 'REMOVE_JOB':
        return { ...state, jobs: state.jobs.filter((job) => job.id !== action.payload) };
      case 'RESET_JOBS':
        return { ...state, jobs: [] };
      default:
        return state;
    }
  }, []);

  const [state, dispatch] = useReducer(jobQueueReducer, initialState);

  return (
    <JobQueueContext.Provider value={{ jobQueueState: state, jobQueueDispatch: dispatch }}>
      {children}
    </JobQueueContext.Provider>
  );
};

export const useJobQueue = (): JobQueueContextProps => {
  const context = useContext(JobQueueContext);

  if (!context) {
    throw new Error('useJobQueue must be used within a JobQueueProvider');
  }

  return context;
};
