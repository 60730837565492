import React, { Suspense } from 'react';
import Navbar from '../navbar/Navbar';
import { useToken } from '../../hooks/useToken';
import { Navigate, Outlet } from 'react-router-dom';
import styles from './LoggedIn.module.scss';
import { useTheme } from '../../styles/themes/ThemeSelector';
import { LoadingPage } from './LoadingPage';
import { Breadcrumbs } from '../breadcrumbs';
import { Container } from '../container';
export const LoggedIn = () => {
  const { token } = useToken();
  const { dashboardBackground } = useTheme();

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className={styles.wrapper}>
      {dashboardBackground}
      <Navbar />
      <Container>
        <Breadcrumbs />
      </Container>
      <Suspense fallback={<LoadingPage />}>
        <Outlet />
      </Suspense>
    </div>
  );
};
