import { useCallback } from 'react';
import { LogicalOperator } from '../model';
import { useUser } from '../../../hooks/useUser';
import { Role } from '../../../enums/Role';

export const useHasAccess = () => {
  const user = useUser();

  return useCallback(
    (roles: Role[] | Role, logicalRelations: LogicalOperator = LogicalOperator.OR) => {
      const rolesInput = Array.isArray(roles) ? roles : [roles];

      if (!user || user.roles.length === 0) {
        return false;
      }

      if (roles.length === 0) {
        return false;
      }

      return logicalRelations === LogicalOperator.AND
        ? rolesInput.every((role) => user.roles.includes(role))
        : rolesInput.some((role) => user.roles.includes(role));
    },
    [user],
  );
};
