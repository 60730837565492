import { useCallback } from 'react';
import { useToken } from './useToken';
import { useQueryClient } from '@tanstack/react-query';
import { useJobQueue } from '../features/jobs-queue';
import { useConfirm } from '../providers/ConfirmProvider';
import { useTranslate } from '../translations';

const useForceLogout = () => {
  const queryClient = useQueryClient();
  const { setToken } = useToken();

  return useCallback(() => {
    setToken('');
    queryClient.clear();
  }, [queryClient, setToken]);
};

const useNormalLogout = () => {
  const translator = useTranslate();
  const forceLogout = useForceLogout();
  const { confirm } = useConfirm();
  const { jobQueueState, jobQueueDispatch } = useJobQueue();
  const hasActiveJob = jobQueueState.jobs.length > 0;

  return useCallback(async () => {
    if (!hasActiveJob) {
      jobQueueDispatch({ type: 'RESET_JOBS' });
      return forceLogout();
    }

    if (await confirm(translator('logout.confirm.active_jobs'))) {
      jobQueueDispatch({ type: 'RESET_JOBS' });
      return forceLogout();
    }
  }, [confirm, forceLogout, hasActiveJob, jobQueueDispatch, translator]);
};

export const useLogout = (force = false) => (force ? useForceLogout() : useNormalLogout());
