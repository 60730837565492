import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

const viewportContext = createContext<{ width?: number; height?: number }>({});

export const ViewportProvider = ({ children }: PropsWithChildren) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleWindowResize = useCallback(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  return <viewportContext.Provider value={{ width, height }}>{children}</viewportContext.Provider>;
};

export const useViewportContext = () => {
  return useContext(viewportContext);
};
