import { IS_SAFE_IN_HUNGARY_APP } from '../config';

const hostUrlArray = window.location.host.split('.');
const subDomain = hostUrlArray.length > 1 ? hostUrlArray[0].replaceAll('_', '').replaceAll('-', '') : null;

export enum AppType {
  PROHUMAN = 'phu',
  PROPOOL = 'propool',
  SAFE_IN_HUNGARY = 'safe-in-hungary',
  SERVICE_FLOW = 'serviceflow',
  HR_RENT = 'hr-rent',
  PRODIAK = 'prodiak',
}

export const getAppName = () => {
  if (subDomain === 'propool') {
    return AppType.PROPOOL;
  }

  if (IS_SAFE_IN_HUNGARY_APP) {
    return AppType.SAFE_IN_HUNGARY;
  }

  if (subDomain === 'serviceflow') {
    return AppType.SERVICE_FLOW;
  }

  if (subDomain === 'hrrent') {
    return AppType.HR_RENT;
  }

  if (subDomain === 'prodiak') {
    return AppType.PRODIAK;
  }

  return AppType.PROHUMAN;
};
