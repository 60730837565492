import React, { PropsWithChildren, ReactElement } from 'react';
import { Button } from '../button';
import { ButtonProps } from '../button/model';
import classNames from 'classnames';
import { CaretDownIcon } from '../icon';

interface DropdownProps extends ButtonProps, PropsWithChildren {
  closeOnClick?: boolean;
  label: ReactElement | string;
}

export const Dropdown = ({ children, label, closeOnClick = true, ...buttonProps }: DropdownProps) => {
  return (
    <div className="dropdown">
      <Button
        color="dark"
        {...buttonProps}
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close={closeOnClick ? 'true' : 'outside'}
      >
        {label} {<CaretDownIcon />}
      </Button>
      {<ul className="dropdown-menu">{children}</ul>}
    </div>
  );
};

interface DropdownItemProps extends ButtonProps {}

const DropDownItem = ({ className, ...buttonProps }: DropdownItemProps) => {
  return (
    <li className={className}>
      <Button
        textAlign="start"
        color="light"
        className={classNames('dropdown-item py-2', className)}
        {...buttonProps}
      />
    </li>
  );
};

const DropDownDivider = () => {
  return <hr className="dropdown-divider" />;
};

Dropdown.Item = DropDownItem;
Dropdown.Divider = DropDownDivider;
